.topmessage
{
	margin-top: 8px;
	margin-bottom: 8px;
}

.sukui
{
	color: crimson;
}
