body {
	background-color: #f8f8f8;
	padding-left: 2rem;
    padding-right: 2rem;
    padding-top: .5rem;
	padding-bottom: 8rem;
}

span {
    display: inline-block;
}
