.img-simple-map {
    display: block;
    height: auto;
    max-width: 100%;
	margin-top: 2rem;
	margin-bottom: 1rem;
}

@media screen and (min-width: 747px) {
    .img-simple-map {
        max-width: 721px;
        margin-left: auto;
        margin-right: auto;
    }
}
